import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import LatestVehicles from "themes/module_latest_vehicles_slider/static/js/latestVehicles";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";

import RecentlyVehiclesSlider from "themes/module_recent_vehicles_slider/static/js/recentVehiclesSlider";

export default class HomePage {
  public static init(siteConfig: SiteConfig, pageConfig: PageConfig) {
    Page.ready(function () {
        $('.slick--home-slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          autoplay: false,
          autoplaySpeed: 3000,
          fade: true,
          cssEase: 'linear'
        });
    });
    const financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
    // init latest vehicles on home page (not in sidebar like other pages)
    LatestVehicles.init(siteConfig.latestVehiclesConfig.home, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("home") != -1),financeSearchTerms, siteConfig);

    LatestVehicles.init(siteConfig.latestVehiclesConfig.main, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("main") != -1),financeSearchTerms, siteConfig);
    
    RecentlyVehiclesSlider.init("recentlyadded");
    RecentlyVehiclesSlider.init("recentlysold");
  }  
}
